<template>
  <div>
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryForm.status" placeholder="请选择" clearable>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryForm.type" placeholder="请选择" clearable>
          <el-option label="早餐" :value="1"></el-option>
          <el-option label="午餐" :value="2"></el-option>
          <el-option label="晚餐" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="handleAdd" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
      border stripe size="small" style="width: 100%;" max-height="500px">
      <el-table-column label="类型">
        <template v-slot="{ row }">
          {{ typeDict[row.type].name }}
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="meal_name"></el-table-column>
      <el-table-column label="单价" prop="price"></el-table-column>
      <el-table-column label="主料" prop="meal_desc"></el-table-column>
      <el-table-column label="图片">
        <template v-slot="{ row }">
          <el-image style="width: 100px" :src="row.photo" :preview-src-list="[row.photo]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button size="mini" type="primary" @click="handleEdit(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="prev, pager, next, total, jumper" :total="total"
        :current-page.sync="queryForm.page" :page-size="queryForm.page_size" @current-change="getTableData">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="showDialog" width="500px" :close-on-click-modal="false"
      :before-close="cancel">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="图片" prop="photo">
          <el-upload :action="UPLOAD_URL" :show-file-list="false" :on-success="handleAvatarSuccess">
            <img v-if="form.photo" :src="form.photo" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择" clearable>
            <el-option label="早餐" :value="1"></el-option>
            <el-option label="午餐" :value="2"></el-option>
            <el-option label="晚餐" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="meal_name">
          <el-input type="text" placeholder="请输入内容" v-model="form.meal_name"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="meal_desc">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="form.meal_desc"></el-input>
        </el-form-item>
        <el-form-item label="口味" prop="flavour">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="form.flavour"></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input v-model="form.price" type="text"></el-input>
        </el-form-item>
        <el-form-item label="商家" prop="merchant_id">
          <el-select v-model="form.merchant_id" filterable clearable placeholder="请选择">
            <el-option v-for="item in shopData" :key="item.id" :label="item.name" :value="item.id">
              <div class="flex justify-content-s-b">
                <span>{{ item.name }}</span>
              </div>
            </el-option>
            <el-pagination small layout="prev, pager, next, total" :total="shopTotal"
              :current-page.sync="shopQuery.page" :page-size="shopQuery.page_size" @current-change="getShops">
            </el-pagination>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="cancel">关闭</el-button>
        <el-button type="primary" @click="confirm">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getMealListAPI,
    saveMealListAPI,
    getMerchantListAPI
  } from '@/api/order.js'
  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  export default {
    name: 'MenuList',
    data() {
      return {
        UPLOAD_URL,
        // 表格请求参数
        queryForm: {
          page: 1,
          page_size: 10,
          status: 1,
          type: '',
        },

        // 表格数据加载状态
        tableLoading: false,
        // 表格数据
        tableData: [],
        // 表格数据长度
        total: 1,

        // 类型常量
        typeDict: {
          1: {
            name: '早餐',
          },
          2: {
            name: '午餐',
          },
          3: {
            name: '晚餐',
          }
        },

        // 弹窗标题
        dialogTitle: '',
        // 弹窗显示状态
        showDialog: false,

        // 弹窗内容数据
        form: {
          type: '', // 类型
          meal_name: '', // 餐名
          meal_desc: '', // 描述
          status: 1,
          photo: '', // 图片
          flavour: '', // 口味
          merchant_id: 1, // 商家id
          price: 0,
        },

        // 商家列表
        shopData: [],
        // 商家请求参数
        shopQuery: {
          page: 1,
          page_size: 10,

        },
        // 商家总数量
        shopTotal: 1,
      }
    },
    created() {
      this.getTableData();
      this.getShops()
    },
    methods: {
      // 获取表格数据
      getTableData() {
        this.tableLoading = true;
        getMealListAPI(this.queryForm).then(res => {
          this.tableLoading = false;
          this.tableData = res.data;
          this.total = res.total;
        })
      },
      // 查询表格
      handleSearch() {
        this.queryForm.page = 1;
        this.getTableData();
      },
      // 重置搜索条件
      handleReset() {
        this.queryForm.status = '';
        this.queryForm.type = '';
      },
      // 新增菜单
      handleAdd() {
        this.dialogTitle = '新增菜品';
        this.showDialog = true;
      },
      // 编辑菜单
      handleEdit(row) {
        this.dialogTitle = '编辑菜品'
        this.showDialog = true
        this.form.id = row.id;
        this.form.flavour = row.flavour;
        this.form.meal_desc = row.meal_desc;
        this.form.meal_name = row.meal_name;
        this.form.photo = row.photo;
        this.form.type = row.type;
        this.form.merchant_id = row.merchant_id;
        this.form.price = row.price
      },
      // 上传图片
      handleAvatarSuccess(res) {
        this.form.photo = res.data.url;
      },
      // 关闭弹窗
      cancel() {
        this.showDialog = false;
        this.form.type = '';
        this.form.meal_name = '';
        this.form.meal_desc = '';
        this.form.photo = '';
        this.form.flavour = '';
        this.form.merchant_id = 1;
        this.form.price = 0
        delete this.form.id
      },
      // 提交表单
      confirm() {
        if (isNaN(this.form.price)) {
          return this.$message.error('单价输入的不是数值')
        }
        for (let key in this.form) {
          if (key != 'flavour' && key != 'price') {
            if (!this.form[key]) {
              return this.$message.error('除口味外都必填')
            }
          }
        }

        saveMealListAPI(this.form).then(() => {
          this.$message.success(`${this.form.id ? '编辑' : '新增'}成功`);
          this.cancel();
          this.getTableData();
        })
      },
      // 获取商家
      getShops() {
        getMerchantListAPI(this.shopQuery).then((res) => {
          this.shopData = res.data;
          this.shopTotal = res.last_page;
        })
      },
    }
  }
</script>


<style scoped>
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    line-height: 100px;
    text-align: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
</style>